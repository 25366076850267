import React, { useState } from 'react';
import styled from '@emotion/styled';
import TextBox from './TextBox';
import { Box, TextStyled, Heading } from '../design-system';
import { useTheme } from 'emotion-theming';
import Icon from './Icon';

const CardStyled = styled(Box)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  border: ${props => (props.active ? `2px solid ${props.theme.colors.primary}` : `2px solid transparent`)};
  box-shadow: ${props => (props.active ? `0 4px 12px ${props.theme.colors.primary}40` : '0 2px 4px rgba(0,0,0,0.1)')};

  &:hover {
    cursor: pointer;
    transform: translateY(-4px);
    box-shadow: 0 6px 16px ${props => props.theme.colors.primary}30;
  }

  &:active {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px ${props => props.theme.colors.primary}20;
  }

  ${props =>
    props.active &&
    `
    background: ${props.theme.colors.lightGrey};
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: ${props.theme.colors.primary};
    }
  `}
`;

const SelectionIndicator = styled(TextStyled)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: auto;
  font-weight: bold;

  svg {
    position: static;
    width: 16px;
    height: 16px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: ${props => props.size || '105px'};
  height: ${props => props.size || '105px'};
  z-index: 1;
`;

const Card = ({
  background,
  width,
  color,
  iconBackground,
  buttonText,
  description,
  iconSize,
  as,
  icon = 'calculator',
  heading,
  selected,
  setSelected,
  i,
  item,
  setProductSelection,
  setError,
  addContraLoanAccount,
  preSelected,
}) => {
  const theme = useTheme();
  const [checked, setChecked] = useState(preSelected);

  const handleCheckbox = () => {
    setChecked(!checked);
    addContraLoanAccount(item.balanceId, { isSelected: !checked, ...item });
  };

  return (
    <CardStyled
      p='24px'
      borderRadius={8}
      width={width}
      bg={background}
      selected={selected}
      onClick={() => {
        setError('');
        setSelected(i);
        if (setProductSelection) setProductSelection(item);
        if (addContraLoanAccount) handleCheckbox();
      }}
      active={addContraLoanAccount ? checked : selected === i}
    >
      <IconWrapper size={iconSize}>
        <Icon icon={icon} color={color === theme.colors.secondary && theme.colors.primary} bg={iconBackground} size={iconSize} />
      </IconWrapper>
      <Heading as={as} color={color} width='70%' headingSize={[3, 4, null, null]} mb='20px'>
        {heading}
      </Heading>
      <TextBox as={as} color={color} width='70%' headingSize={[3, 4, null, null]} mb='20px'>
        <TextStyled mt='0' fontSize={[1, 2]} lineHeight={['19px', '23px']}>
          {description}
        </TextStyled>
      </TextBox>

      <SelectionIndicator color={(addContraLoanAccount ? checked : selected === i) ? theme.colors.primary : theme.colors.gray}>
        {buttonText || ((addContraLoanAccount ? checked : selected === i) ? 'Selected' : 'Select this loan')}
      </SelectionIndicator>
    </CardStyled>
  );
};

export default Card;
